define("dummy/templates/dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d/biHlcT",
    "block": "{\"symbols\":[\"sg\",\"dd\"],\"statements\":[[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FDropdown\",\"Dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-dropdown\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"trigger\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Click Me\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,2,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"Switch to\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,2,[\"linkTo\"]],[],[[\"@route\"],[\"home\"]],[[\"default\"],[{\"statements\":[[2,\"Home\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,2,[\"linkTo\"]],[],[[\"@route\"],[\"dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"Dropdown\"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "dummy/templates/dropdown.hbs"
    }
  });

  _exports.default = _default;
});