define("dummy/templates/components/style-guide/example", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xHoAlaSa",
    "block": "{\"symbols\":[\"@name\",\"&default\"],\"statements\":[[10,\"h4\"],[12],[2,\"Snippet\"],[13],[2,\"\\n\\n\"],[10,\"pre\"],[12],[2,\"<\"],[1,[32,1]],[2,\">\"],[13],[2,\"\\n\\n\"],[10,\"h4\"],[12],[2,\"Output\"],[13],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "dummy/templates/components/style-guide/example.hbs"
    }
  });

  _exports.default = _default;
});