define("dummy/templates/components/style-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KMh4C+Ni",
    "block": "{\"symbols\":[\"@title\",\"@name\",\"&default\"],\"statements\":[[8,\"f-card\",[[24,0,\"Mb-25\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"h2\"],[12],[1,[32,1]],[13],[2,\"\\n\\n  \"],[18,3,[[30,[36,3],null,[[\"example\",\"form-for\"],[[30,[36,2],[\"style-guide/example\"],[[\"name\"],[[32,2]]]],[30,[36,2],[\"f-form-for\"],[[\"classNames\"],[[30,[36,1],[[30,[36,0],[\"f-form-for\"],[[\"from\"],[\"dummy/styles/components/style-guide\"]]],\" Mb-15\"],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"local-class\",\"concat\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "dummy/templates/components/style-guide.hbs"
    }
  });

  _exports.default = _default;
});