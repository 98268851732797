define("dummy/styles/components/style-guide", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "f-form-for": "_f-form-for_1m9lxa"
  };
  _exports.default = _default;
});