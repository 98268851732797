define("dummy/routes/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return {
        value: 'Fatsoma',
        isPending: false,
        disabled: false
      };
    },

    actions: {
      onFacebookSuccess(response) {
        Ember.debug('success', response);
      },

      onFacebookFailure(response) {
        Ember.debug('failure', response);
      }

    }
  });

  _exports.default = _default;
});