define("dummy/templates/blank-slate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3ojCkb7p",
    "block": "{\"symbols\":[\"sg\"],\"statements\":[[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FBlankSlate\",\"Blank slate\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-blank-slate\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "dummy/templates/blank-slate.hbs"
    }
  });

  _exports.default = _default;
});