define("dummy/templates/paginate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l771CiA3",
    "block": "{\"symbols\":[\"sg\"],\"statements\":[[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FPaginate\",\"Paginate\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-paginate\",[],[[\"@routeName\",\"@current\",\"@total\"],[[30,[36,0],[\"paginate\"],null],[30,[36,0],[[32,0,[\"page\"]]],null],[30,[36,0],[[32,0,[\"model\",\"total\"]]],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"readonly\"]}",
    "meta": {
      "moduleName": "dummy/templates/paginate.hbs"
    }
  });

  _exports.default = _default;
});