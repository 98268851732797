define("dummy/translations/en", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "f_blank_slate": {
      "default": "Nothing to show here yet."
    },
    "f_facebook_login": {
      "text": "Sign in with Facebook"
    },
    "f_paginate": {
      "next": "Next &rarr;",
      "prev": "&larr; Prev"
    },
    "f_pending": {
      "text": "Loading..."
    }
  };
  _exports.default = _default;
});