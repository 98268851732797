define("dummy/templates/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9Uu/CZyS",
    "block": "{\"symbols\":[\"sg\"],\"statements\":[[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FTag\",\"Tag\"]],[[\"default\"],[{\"statements\":[[2,\"\\n \"],[8,[32,1,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-tag\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      Test\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "dummy/templates/tag.hbs"
    }
  });

  _exports.default = _default;
});