define("dummy/components/style-guide/example", ["exports", "dummy/templates/components/style-guide/example"], function (_exports, _example) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const StyleGuideExample = Ember.Component.extend({
    layout: _example.default
  });
  StyleGuideExample.reopenClass({
    positionalParams: ['param']
  });
  var _default = StyleGuideExample;
  _exports.default = _default;
});