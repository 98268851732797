define("dummy/components/style-guide", ["exports", "dummy/templates/components/style-guide", "dummy/styles/components/style-guide"], function (_exports, _styleGuide, _styleGuide2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const StyleGuide = Ember.Component.extend({
    layout: _styleGuide.default,
    styles: _styleGuide2.default
  });
  StyleGuide.reopenClass({
    positionalParams: ['name']
  });
  var _default = StyleGuide;
  _exports.default = _default;
});