define("dummy/routes/paginate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    model() {
      return {
        total: 10
      };
    }

  });

  _exports.default = _default;
});