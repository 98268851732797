define("dummy/templates/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Dspu7bNg",
    "block": "{\"symbols\":[\"sg\",\"sg\",\"f\",\"@model\"],\"statements\":[[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FButton\",\"Button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,2,[\"form-for\"]],[],[[\"@model\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,3,[\"input\"]],[],[[\"@attribute\"],[\"value\"]],null],[2,\"\\n    \"],[8,[32,3,[\"select\"]],[],[[\"@attribute\",\"@options\",\"@includeBlank\"],[\"mod\",\"secondary\",true]],null],[2,\"\\n    \"],[8,[32,3,[\"checkbox\"]],[],[[\"@attribute\"],[\"isPending\"]],null],[2,\"\\n    \"],[8,[32,3,[\"checkbox\"]],[],[[\"@attribute\"],[\"disabled\"]],null],[2,\"\\n  \"]],\"parameters\":[3]}]]],[2,\"\\n\\n  \"],[8,[32,2,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-button\",[],[[\"@value\",\"@isPending\",\"@disabled\",\"@mod\"],[[32,4,[\"value\"]],[32,4,[\"isPending\"]],[32,4,[\"disabled\"]],[32,4,[\"mod\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\\n\"],[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FFacebookLogin\",\"Facebook Login Button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-facebook-login\",[],[[\"@onSuccess\",\"@onFailure\"],[[30,[36,0],[\"onFacebookSuccess\"],null],[30,[36,0],[\"onFacebookFailure\"],null]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\"]}",
    "meta": {
      "moduleName": "dummy/templates/button.hbs"
    }
  });

  _exports.default = _default;
});