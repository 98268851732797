define("dummy/routes/forms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.Object.create({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        type: 'promoter',
        agree: false,
        dob: '',
        errors: {
          base: Ember.A([{
            message: 'Please check your internet connection'
          }]),
          ['email']: Ember.A([{
            message: 'Invalid email address'
          }])
        }
      });
    },

    actions: {
      submit() {
        alert('computer says no!');
      }

    }
  });

  _exports.default = _default;
});