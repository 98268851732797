define("dummy/components/f-input-for", ["exports", "ember-web-ui/components/f-input-for"], function (_exports, _fInputFor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fInputFor.default;
    }
  });
});