define("dummy/templates/tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dvU1PU2v",
    "block": "{\"symbols\":[\"sg\",\"tab\"],\"statements\":[[8,\"style-guide\",[],[[\"@name\",\"@title\"],[\"FTabs\",\"Tabs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"example\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"f-tabs\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2,[\"item\"]],[],[[\"@route\"],[\"tabs.index\"]],[[\"default\"],[{\"statements\":[[2,\"Tab 1\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,[32,2,[\"item\"]],[],[[\"@route\"],[\"tabs.example\"]],[[\"default\"],[{\"statements\":[[2,\"Tab 2\"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "dummy/templates/tabs.hbs"
    }
  });

  _exports.default = _default;
});